import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Subscribe from '../components/subscribe'
import { Link } from 'gatsby'

const styles = require('../styles/faq.module.css')
const classNames = require('classname')

const FAQpage: React.FC = () => (
    <Layout>
        <SEO title="FAQ"/>
            <div className={styles.container}>
                <div className={classNames({ [styles.main]: true, [styles.mainRaised]: true })}>
                    <div className={styles.blueBanner}>
                    <div className={styles.space50}></div>
                
                    
                <div className={styles.space100}></div>
                    <div className={styles.header}>
                        <h1 className={styles.statement}>Frequently Asked Questions</h1>
                       
                    </div>
                    <div className={styles.space50}></div>
                
                    
                <div className={styles.space100}></div>
                </div>
                <div className={styles.mainPadding}>
                <div className={styles.space50}></div>
                    <div>
                        <h2>Who is Park to Give?</h2>
                        <p>We are a local start-up based in our hometown of Columbia, SC and are proud Gamecocks!</p>
                    </div>
                    <div>
                        <h2>Why are you doing this?</h2>
                        <p>We want to create a new model for generating economic and social good in our community – and also fill a practical need.</p>
                    </div>
                    <div>
                        <h2>Does the United Way of the Midlands really benefit from Park to Give?</h2>
                        <p>Absolutely, this is the most important part of our mission.  We promise all net proceeds from what you pay goes directly to the United Way of the Midlands. 
                            All expenses, including credit card payment and transaction fees, will be deducted from the parking space owner's portion of the proceeds.</p>
                    </div>
                    <div>
                        <h2>Will I be ticketed or towed if I park during No Parking hours or fail to pay for parking during Pay to Park hours?</h2>
                        <p>To maintain a sustainable model for parking and giving, anyone parking during No Parking hours or failing to pay during Pay to Park hours may be ticketed or towed.</p>
                    </div>
                    <div>
                        <h2>Is this free parking?</h2>
                        <p>Park to Give parking is paid parking, not free parking.  We created this model because we believe in supporting our local community - <b><i>and</i></b> we want to provide you a convenient option to park that enables you share in that support. </p>
                    </div>
                    <div>
                        <h2>Do I have to register or login to use Park to Give?</h2>
                        <p>To make parking as fast and convenient as possible, we currently do not require any registration or login.  All you need is Apple Pay, Google Pay, or any major credit card to park. 
                            Optionally, you can provide an email address if you would like a receipt sent to you.</p>
                    </div>
                    <div>
                        <h2>Are my payments secure?</h2>
                        <p>Park to Give uses Stripe, an industry leading secure payment processing platform.  Park to Give does not store any payment information 
                            and all transactions are completed over an encrypted secure connection. <a href="https://stripe.com/docs/security/stripe">Learn more about Stripe security.</a></p>
                    </div>
                    <div>
                        <h2>What payment methods do you accept?</h2>
                        <p>We support Apple Pay, Google Pay and all major credit cards.  We hope to add additional payment options in the future.</p>
                    </div>
                    <div>
                        <h2>What devices are supported?</h2>
                        <p>We support both iOS and Android devices and all major browsers including Safari, Chrome, and Firefox.</p>
                    </div>
                    <div>
                        <h2>Do you have an app?</h2>
                        <p>We are currently working on a Park to Give app so <Link to="/sign-up/">click here</Link> to receive new updates!</p>
                    </div>
                    <div>
                        <h2>What if I can't scan the QR code on the sign?</h2>
                        <p>You can always access our payment site directly at <a href="https://www.park.gives">www.park.gives</a></p>
                    </div>
                    <div>
                        <h2>I have parking spaces that might fit the Park to Give business model.  How can I find out more?</h2>
                        <p>We are excited that you are interested in partnering with us! Please <Link to="/contact/">contact us</Link> and our team will reach out to provide more information.</p>
                    </div>
                    <div>
                        <h2>Does Park to Give partner with other charities?</h2>
                        <p>Right now, our hope is to impact our local community for good and by partnering with the United Way of the Midlands, we believe we can do that.  
                            We may partner with other charities in the future that share our mission and values.</p>
                    </div>
                    <div>
                        <h2>Can I claim the amount given to the United Way of the Midlands as a tax-deductible donation?</h2>
                        <p>Sorry, because you are paying for parking and not donating directly to the United Way of the Midlands, the IRS will not allow a deduction, despite the fact that the United Way of the Midlands will receive a portion of the payment.</p>
                    </div>
                    <div>
                        <h2>Who can I contact if I have any problems, questions, suggestions, or just want to connect?</h2>
                        <p>We would love to hear from you!  You can email us anytime at info@parktogive.com or call us M-F 9-5pm at (803) 939-6831.</p>
                    </div>
                    <div className={styles.space100}></div>
                <Subscribe/>
                </div>
                </div>
            </div>

    </Layout>
)

export default FAQpage